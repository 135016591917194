import axios from 'axios'
import { notifyError } from "../../../utility/Utils";
import jwtDefaultConfig from './jwtDefaultConfig'

const excludeUrls = ["/api/v2/initial-admin/user-create"];
export default class JwtService {
  jwtConfig = { ...jwtDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []


  constructor(jwtOverrideConfig) {
    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }
    // axios.defaults.baseURL = "https://api.uat.go-smart.app"; // ** 本番用
    axios.defaults.baseURL = "https://stg.uat.gosmart.jp"; // ** ステージング用
    // axios.defaults.baseURL = "https://localhost:5443";

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = JSON.parse(this.getToken())

        // ** If token is present add it to request's Authorization Header
        if (accessToken) {
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          if (config.url.indexOf('api/v2/initial-admin/notification-announcement-create') != -1) {
            config.headers['Content-Type'] = 'multipart/form-data';
          }
        }
        return config
      },
      error => Promise.reject(error)
    )

    let hasShownLogoutToast = false;
    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        const { config, response } = error
        if (response && response.status === 401) {
          if (this.jwtConfig.storageData && !hasShownLogoutToast) {
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem('userData')
            notifyError("セキュリティ確保の理由によりログアウトされました。恐れ入りますが再度ログインしてください ")
            hasShownLogoutToast = true;
            setTimeout(
              () => {
                window.location.replace("/login")
                hasShownLogoutToast = false
              },
              3000
            );
          }
          return
        }

        if (response && response.status === 403) {
          if (this.jwtConfig.storageData && !hasShownLogoutToast) {
            localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
            localStorage.removeItem(this.jwtConfig.storageData)
            notifyError(response.data.message)
            hasShownLogoutToast = true;
            setTimeout(
              () => {
                window.location.replace("/login")
                hasShownLogoutToast = false
              },
              3000
            );
          }
          return
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.login, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken()
    })
  }

  /**Employee user */
  addEmployee(...args) {
    return axios.post(this.jwtConfig.employee_create, ...args)
  }

  getEmployeeList() {
    return axios.get(this.jwtConfig.employee_list);
  }

}
