import auth from './authentication'
import layout from './layout'
// ** Reducers Imports
import navbar from './navbar'

const rootReducer = {
  auth,
  navbar,
  layout,
}

export default rootReducer
